.EditMarketingV2 {
  .jingang {
    .main {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 15px 10px 0;

        .image-box {
          cursor: pointer;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          align-items: center;

          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }

        .title {
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
  }

  .index-banner {
    .banner-item {
      border-radius: 15px;
      width: 474px;
      height: 151px;
      background-image: url("https://gy-kjds-prod.obs.cn-southwest-2.myhuaweicloud.com/20220524/e702303982c771eeacce71c48ce4396d.png");
      background-size: 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      img {
        border-radius: 15px;
      }

      .left {
        padding: 10px;
        width: 310px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          color: #262626;
          font-size: 16px;
          font-weight: 500;
        }
      }

      .right {
        padding: 10px;
      }

      .image-box {
        position: absolute;
      }

      .edit-button {
        position: absolute;
        bottom: 10px;
        margin-left: 10px;
      }
    }
  }

  .floor-list {
    display: flex;
    flex-direction: row;
    background-color: #F7F7F7;
    border-radius: 6px;
    padding: 10px;
    justify-content: space-between;
    margin-bottom: 10px;

    .left {
      display: flex;
      flex-direction: column;
      width: 370px;

      .title-box {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 5px;

        .title {
          font-size: 16px;
          font-weight: 500;
        }

        .label-title {
          font-size: 12px;
          margin-left: 5px;
        }
      }

      .tag-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
      }

      .spu-box {
        display: flex;
        flex-direction: row;
        margin-top: 2px;
        align-items: center;

        .spu-num {
          font-weight: 500;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .sort {
        display: flex;
        flex-direction: column;
        margin-right: 15px;
        font-size: 12px;

        .item {
          cursor: pointer;
        }
      }

      .edit {
        font-size: 12px;
        margin-right: 15px;
        cursor: pointer;
      }

      .spu {
        font-size: 12px;
        margin-right: 15px;
        cursor: pointer;
      }

      .delete {
        font-size: 12px;
        cursor: pointer;
        color: #ff4d4f;
      }
    }
  }
}