.LoadingLayer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.5);

    .spin {
        position: absolute;
        top: 49%;
        left: 49%;
    }
}