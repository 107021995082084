.Login {
    position: absolute;
    background-image: url("https://bj-prod-1309320985.cos.ap-beijing.myqcloud.com/20230110/ab349d4d6d7caa9bfd22aa88d4abe0af.png");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;

    .card {
        position: absolute;
        left: 50%;
        margin-left: -230px;
        top: 50%;
        margin-top: -200px;
        width: 460px;
    }

    .icp {
        position: absolute;
        width: 100%;
        top: calc(50% + 200px);
        text-align: center;

        a {
            color: #bbb;
        }
    }
}