.CommonLayout {
    .logo {
        float: left;
        width: 240px;
        color: #1f1f1f;
        font-size: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .avatar {
        line-height: 60px;
        float: right;
        width: 220px;
        font-size: 24px;

        span {
            margin-left: 10px;
            color: #262626;
            font-size: 16px;
        }

        a {
            margin-left: 25px;
            color: #595959;
            font-size: 14px;
        }
    }

    .sider-background {
        background: #fff;
    }
}