.OrderList {
    margin-top: 10px;

    .search-box {
        margin-top: 15px;
    }

    .select {
        width: 200px;
    }
}