.EditMarketing {
    .submit-box {
        background-color: #ffffff;
        width: 100%;
        height: 60px;
        box-shadow: 0px 0px 10px #888888;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .detail-box {
        padding-bottom: 60px;

        .tab-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .tabs-modal {
        .tab-item {
            display: flex;
            flex-direction: row;
        }
    }
}