.EditPainterPagePlan {
    .container {
        width: 100%;
        height: 80vh;
        background-color: #000;
        display: flex;
        flex-direction: row;

        .templates {
            overflow-y: scroll;
            width: 25%;
            height: 100%;
            background-color: #fff1f0;
            display: flex;
            flex-direction: column;
            padding: 20px;
        }

        .plan-templates {
            overflow-y: scroll;
            width: 25%;
            height: 100%;
            background-color: #e6fffb;
            padding: 20px;
        }

        .preview {
            overflow-y: scroll;
            width: 25%;
            height: 100%;
            background-color: #f9f0ff;
            padding: 20px;

            .content {
                width: 100%;
            }
        }

        .plan-template-config {
            overflow-y: scroll;
            width: 25%;
            height: 100%;
            background-color: #e6f7ff;
            padding: 20px;
        }
    }
}